<template>
  <!-- default Vue navbar
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  -->
  <q-layout view="hHh lpR fFf">
    <router-view/>
  </q-layout>
</template>

<style>
</style>

<script>

export default {
  name: 'App',
  components: {
  },
  created () {
    document.title = "Zerudite"
  }
}
</script>
<style>
template {
  cursor: default !important;
}
</style>
