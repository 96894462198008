import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/Homepage.vue')
  },
  {
    path: '/homepage',
    component: () => import('../views/Homepage.vue')
  },
  {
    path: '/faq',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/requests',
    component: () => import('../views/Requests.vue')
  },
  {
    path: '/shop',
    component: () => import('../views/ShopTemp.vue')
  },
  {
    path: '/profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/navbar',
    component: () => import('../components/NavBar.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/profile/:username',
    name: 'ProfilePageForOtherPerson',
    component: () => import('../views/OthersProfile.vue'),
  },
  {
    path: '/session/:conference',
    name: 'sessionConference',
    component: () => import('../views/sessionConference.vue')
  },
  {
    path: '/403',
    name: 'FourOThree',
    component: () => import('../components/403.vue')
  },
  {
    path: '/earlySession',
    name: 'Early',
    component: () => import('../components/earlySession.vue')
  },
  {
    path: '/noSession',
    name: 'NoSession',
    component: () => import('../components/noSession.vue')
  },
  {
    // default 404
    path: '/:pathMatch(.*)*',
    component: () => import('../components/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
