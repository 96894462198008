import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import 'animate.css'
import firebase from 'firebase'
import LoadScript from 'vue-plugin-load-script'


const firebaseConfig = {
    apiKey: "AIzaSyDIg8eX7nuD8um-DC9NbE2bNOsw0ovQAvw",
    authDomain: "zerudite-auth.firebaseapp.com",
    projectId: "zerudite-auth",
    storageBucket: "zerudite-auth.appspot.com",
    messagingSenderId: "342146024620",
    appId: "1:342146024620:web:7d9307e7499a635eca0b09",
    measurementId: "G-4X7VK378Z3"
};

firebase.initializeApp(firebaseConfig)

// createApp(App).use(Quasar, quasarUserOptions).use(router).mount('#app')

const app = createApp(App)

app.config.globalProperties.$backendURL = "https://zerudite8xdgcauu-backend1.functions.fnc.fr-par.scw.cloud"
    app.use(Quasar, quasarUserOptions)
app.use(router)
app.use(LoadScript)
app.mount('#app')